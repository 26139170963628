export default () => {
  return (text: string | undefined, wordCount: number = 25) => {
    if (!text) return ''

    const splitToArr = text.split(' ')
    const sliceOnWordCount = splitToArr.slice(0, wordCount)
    const joinToStr = sliceOnWordCount.join(' ')
    const removeHtmlTags = joinToStr.replace(/<[^>]+>/g, '')

    return `${removeHtmlTags}...`
  }
}
